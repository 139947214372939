import config from "../aws-exports";
import styled from "styled-components";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function getEnvNameFromConfig(amplifyConfig) {
  const oauthDomain = amplifyConfig["oauth"]["domain"];
  const envRegex = /.+?-(.*?)\..*/;
  const match = oauthDomain.match(envRegex);
  return match[1];
}

function getEnvName() {
  return getEnvNameFromConfig(config);
}

const Centerer = styled.div`
  width:100%;
  display: flex;
  justify-content: center;
  flex-wrap:wrap;
`;

const VerticalCenterer = styled(Centerer)`
  flex-direction: column;
  align-items:center;
`;

export { sleep, getEnvName, getEnvNameFromConfig, Centerer, VerticalCenterer };
