/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://cwh24zmllvfixatfjnd3liibo4.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-1:5ef64306-b797-4391-aa3b-3f4601253b5f",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_r0th4nrIw",
    "aws_user_pools_web_client_id": "5j7j630qh8r0i63qmg8a0vfpua",
    "oauth": {
        "domain": "fromprogredirect-prod.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:8000/,https://dev.d2r0sjpr4a8ew5.amplifyapp.com/,https://prod.d2r0sjpr4a8ew5.amplifyapp.com/",
        "redirectSignOut": "htp://localhost:8000/,https://dev.d2r0sjpr4a8ew5.amplifyapp.com/,https://prod.d2r0sjpr4a8ew5.amplifyapp.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "fromProgUserCredits-prod",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;
