import Theme from "./src/components/theme";
import React from "react";
import { Amplify } from "aws-amplify";
import config from "./src/aws-exports";
import { Authenticator } from "@aws-amplify/ui-react";
import { CreditContextWrapper } from "./src/components/credits/credits_context";
import { getEnvNameFromConfig } from "./src/components/utils";

const env = getEnvNameFromConfig(config);
// From https://docs.amplify.aws/lib/auth/social/q/platform/js/#configure-auth-category
// We choose here which url to redirect the user to after social sign in

// TODO: replace the prod url by fromprog.com when dns is setup
const env2RemoteLink = {
  dev: "https://dev.d2r0sjpr4a8ew5.amplifyapp.com/",
  prod: "https://prod.d2r0sjpr4a8ew5.amplifyapp.com/",
};

//locallink,remoteLink = config.oauth.redirectSignIn.split(","); // not working for some reason
const remoteLink = env2RemoteLink[env];
const localLink = "http://localhost:8000/";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production

const [localRedirectSignIn, productionRedirectSignIn] = [localLink, remoteLink];
console.log("SIGNIN", localRedirectSignIn);
console.log("prod SIGNIN", productionRedirectSignIn);

const [localRedirectSignOut, productionRedirectSignOut] = [
  localLink,
  remoteLink,
];
console.log("SIGNOUT", localRedirectSignOut);
console.log("prod SIGNOUT", productionRedirectSignOut);

// Extract region
const { aws_project_region: region } = config;

// Update amplify configuration
const updatedAwsConfig = {
  ...config,
  oauth: {
    ...config.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut,
  },
  AWSS3: { region: region },
};
Amplify.configure(updatedAwsConfig);

export function wrapRootElement({ element }) {
  return (
    <Authenticator.Provider>
      <CreditContextWrapper>
        <Theme>{element}</Theme>
      </CreditContextWrapper>
    </Authenticator.Provider>
  );
}
export const onInitialClientRender = () => {
// TODO: check if this resolves the white space above navbar
//   window.scrollTo(0, 0);
};
