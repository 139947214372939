import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { Normalize } from "styled-normalize";

const mainTheme = {
  maincolor: {
    color1 : "#0085A1",
    color2 : "#c1b27f",
    color2_diluted :"rgba(214,206,178)",
    almost_white:"rgba(245, 245, 245, 1.0)",
    color3: "#E25098",
    light_grey: "#aaaaaa",
    funcky_cheese: "#dbad23",

    // color3: "#c1b27f",

    text: "grey",
    background: "white",
    social_icons: "#B9B9B9",
  },
  header: {
    background_color: "#555555",
    text_color: "white",
    height: "7em",
  },
  fonts: {
    header: "Montserrat",
    copy: "Source Sans Pro",
  },
  body: {
    fontsize: "1.3em"
  }
};

const GlobalStyle = createGlobalStyle`

    html {
    box-sizing: border-box;
    }
    *, *::before, *::after {
    box-sizing: inherit;
    }
    body {
        background: ${({ theme }) => theme.maincolor.background};
        color: ${({ theme }) => theme.maincolor.text};
        font-family: ${({ theme }) => theme.fonts.copy},sans-serif;
    h1, h2, h3 {
        font-family: ${({ theme }) => theme.fonts.header},sans-serif;
    }
    }
`;

const Theme = ({ children }) => {
  // Pull the data from graphql
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <ThemeProvider theme={mainTheme}>
      <GlobalStyle />
      <Normalize />
      {children}
    </ThemeProvider>
  );
};
export default Theme
export {mainTheme};
