import React, { createContext } from "react";
import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { getCredits } from "../../graphql/queries";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import Cookies from "universal-cookie";
import { MOCK_GQL_API } from "../../components/constants";
export const UserCreditContext = createContext();

export const CreditContextWrapper = ({ children }) => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [creditState, setCreditState] = useState(undefined);
  const [endDateState, setEndDateState] = useState(undefined);

  const userInfo = { user: user, credits: creditState, endDate: endDateState };
  function isAuthenticated() {
    return user !== undefined;
  }

  function hasCreditsInState() {
    return creditState !== undefined && endDateState !== undefined;
  }

  function hasCreditsInCookies() {
    const cookies = new Cookies();
    const email = user.attributes.email;
    var cookiesCreditsStr = cookies.get(`${email}-credits`);
    var cookiesEndDateStr = cookies.get(`${email}-end-date`);
    const hasCookies =
      cookiesCreditsStr !== undefined && cookiesEndDateStr !== undefined;
    return hasCookies;
  }

  function setCredits(credits, endDate) {
    setCreditState(credits);
    setEndDateState(endDate);
  }

  function setCookieCredits(credits, endDate) {
    const cookies = new Cookies();
    const email = user.attributes.email;
    cookies.set(`${email}-credits`, credits, { expires: endDate, path: "/" });
    cookies.set(`${email}-end-date`, endDate.toUTCString(), {
      expires: endDate,
      path: "/",
    });
  }

  function updateCreditsFromCookies() {
    const cookies = new Cookies();
    const email = user.attributes.email;
    var cookiesCreditsStr = cookies.get(`${email}-credits`);
    var cookiesEndDateStr = cookies.get(`${email}-end-date`);
    const cookiesEndDate = new Date(cookiesEndDateStr);
    const cookiesCredits = parseInt(cookiesCreditsStr);
    setCredits(cookiesCredits, cookiesEndDate);
  }

  async function executeCreditGQLquery() {
    const response = await API.graphql(graphqlOperation(getCredits));
    return response;
  }

  async function mockCreditGQLquery() {
    const response = {
      data: {
        getCredits: {
          credits: 6,
          status_code: 200,
          start_date: "2022-12-06T03:56:00Z",
          end_date: "2023-12-06T03:57:00Z",
          description: null,
        },
      },
    };
    return response;
  }

  async function updateCreditsFromAPI() {
    const response = MOCK_GQL_API
      ? await mockCreditGQLquery()
      : await executeCreditGQLquery();

    let credits = response.data.getCredits.credits;
    const endDate = new Date(response.data.getCredits.end_date);
    setCreditsFromAPI(credits, endDate);
  }

  function setCreditsFromAPI(credits, endDate) {
    setCookieCredits(credits, endDate);
    setCredits(credits, endDate);
  }

  async function refreshCredits() {
    if (isAuthenticated()) {
      if (!hasCreditsInState()) {
        if (hasCreditsInCookies()) {
          updateCreditsFromCookies();
        } else {
          updateCreditsFromAPI();
        }
      }
    } else {
    }
  }

  function isReadyToRetry() {
    const now = new Date();
    return now > endDateState;
  }

  function hasSufficientCredits() {
    return creditState > 0;
  }

  useEffect(() => {
    refreshCredits();
  }, [user]);

  return (
    <UserCreditContext.Provider
      value={{
        userInfo,
        isAuthenticated,
        isReadyToRetry,
        hasSufficientCredits,
        setCreditsFromAPI,
      }}
    >
      {children}
    </UserCreditContext.Provider>
  );
};

// export default () => (
//   <Authenticator.Provider>
//     <CreditContextWrapper />
//   </Authenticator.Provider>
// );
