/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getS3DownImage = /* GraphQL */ `
  query GetS3DownImage($id: ID!) {
    getS3DownImage(id: $id) {
      bucket
      region
      key
      status_code
      description
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listS3DownImages = /* GraphQL */ `
  query ListS3DownImages(
    $filter: ModelS3DownImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listS3DownImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        bucket
        region
        key
        status_code
        description
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const performSum = /* GraphQL */ `
  query PerformSum($numbers: String) {
    performSum(numbers: $numbers) {
      status {
        status_code
        description
      }
      credit_info {
        credits
        status_code
        start_date
        end_date
        description
      }
      sum_value
    }
  }
`;
export const getCredits = /* GraphQL */ `
  query GetCredits {
    getCredits {
      credits
      status_code
      start_date
      end_date
      description
    }
  }
`;
export const getSignedURL = /* GraphQL */ `
  query GetSignedURL($bucket: String, $files: [String]) {
    getSignedURL(bucket: $bucket, files: $files) {
      status {
        status_code
        description
      }
      credit_info {
        credits
        status_code
        start_date
        end_date
        description
      }
      fileUrls {
        file
        url
      }
    }
  }
`;
export const generateCheese = /* GraphQL */ `
  query GenerateCheese {
    generateCheese {
      status {
        status_code
        description
      }
      credit_info {
        credits
        status_code
        start_date
        end_date
        description
      }
      bucket
      key
      region
    }
  }
`;
